import { useTracker, useImpression } from '@archipro-website/tracker';
import { useLatest } from 'ahooks';
import type { GTMEcomEventData } from '../util/gtmHelper';
import { useCallback } from 'react';

interface Props {
    data?: GTMEcomEventData;
    ref: React.MutableRefObject<Element | null>;
}

interface UseEcomItemViewEventHandlerReturn {
    onClick: () => void;
}

export const useEcomItemViewEventHandler = ({
    data,
    ref,
}: Props): UseEcomItemViewEventHandlerReturn => {
    const trackerData = useLatest<GTMEcomEventData | undefined>(data);
    const tracker = useTracker();

    useImpression({
        ref,
        disable: !trackerData.current,
        onImpression: async () => {
            const data = trackerData.current;
            if (!data) {
                return;
            }

            tracker.log('view_item_list', {
                targetTracker: 'googleTagManagerTracker',
                url: new URL(window.location.href),
                gtmData: data,
            });
        },
    });

    const onClick = useCallback(() => {
        if (trackerData.current) {
            tracker.log('select_item', {
                targetTracker: 'googleTagManagerTracker',
                url: new URL(window.location.href),
                gtmData: trackerData.current,
            });
        }
    }, [trackerData, tracker]);

    return {
        onClick,
    };
};
