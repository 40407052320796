import type {
    ObjectShorthandValue,
    ProductTileProps,
} from '@archipro-design/aria';
import { ProductTile, useAppDisplayModeContext } from '@archipro-design/aria';
import type { SyntheticEvent } from 'react';
import { useRef } from 'react';
import type { LogImpressionProps } from '@archipro-website/tracker';
import { useEcomItemViewEventHandler } from '~/modules/tracking/hook/useEcomItemViewEventHandler';
import { useLogImpression, useTracker } from '@archipro-website/tracker';
import type { GTMEcomEventData } from '~/modules/tracking/util/gtmHelper';
import { useNavigate } from '@remix-run/react';

export type TileProps = ObjectShorthandValue<ProductTileProps> & {
    impression?: LogImpressionProps;
    remarketingImpression?: GTMEcomEventData;
    sectionSource?: 'Directory' | 'Profile' | 'Search';
};

const ProductImpressionTile = (props: TileProps) => {
    const {
        impression,
        priceCurrency: _,
        remarketingImpression,
        onClick,
        sectionSource,
        ...rest
    } = props;
    const ref = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();

    useLogImpression({ data: impression, ref });
    const tracker = useTracker();

    const appDisplayMode = useAppDisplayModeContext();

    const { onClick: onEcomClick } = useEcomItemViewEventHandler({
        data: remarketingImpression,
        ref,
    });

    const carouselTracker = (direction: 'next' | 'prev') => {
        if (impression) {
            switch (direction) {
                case 'next':
                    tracker.log('CarouselNext', {
                        url: new URL(window.location.href),
                        targetTracker: ['archiproTracker', 'ga4Tracker'],
                        data: {
                            ExtraData: JSON.stringify({
                                source: sectionSource,
                                libraryItemId: impression.itemID,
                            }),
                        },
                        ga4Data: {
                            source: sectionSource,
                            libraryItemId: impression.itemID,
                        },
                    });
                    break;
                case 'prev':
                    tracker.log('CarouselPrev', {
                        url: new URL(window.location.href),
                        targetTracker: ['archiproTracker', 'ga4Tracker'],
                        data: {
                            ExtraData: JSON.stringify({
                                source: sectionSource,
                                libraryItemId: impression.itemID,
                            }),
                        },
                        ga4Data: {
                            source: sectionSource,
                            libraryItemId: impression.itemID,
                        },
                    });
                    break;
            }
        }
    };

    return (
        <ProductTile
            ref={ref}
            onClick={(e: SyntheticEvent) => {
                onEcomClick();
                onClick?.(e);
            }}
            {...rest}
            onDownloadsClick={(e: SyntheticEvent, type: string) => {
                e.preventDefault();
                e.stopPropagation();
                onEcomClick();
                onClick?.(e);
                tracker.log('TileFileIconClick', {
                    url: new URL(window.location.href),
                    targetTracker: ['archiproTracker', 'ga4Tracker'],
                    data: {
                        downloadType: type,
                    },
                    ga4Data: {
                        downloadType: type,
                    },
                });
                if (rest.to) {
                    navigate(`${rest.to}?openDownloadModal=1`);
                }
            }}
            trackCarouselActivity={carouselTracker}
            appDisplayMode={appDisplayMode}
        />
    );
};

export default ProductImpressionTile;
